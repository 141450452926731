import {createRouter, createWebHistory} from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'home',
    },
  },
 /* {
    name: 'user',
    path: '/user',
    component: () => import('./view/user'),
    meta: {
      title: '会员中心',
    },
  },*/
  {
    name: 'home',
    path: '/',
    component: () => import('./view/home'),
    meta: {
      title: '狮城头条',
    },
  },
  {
    name: 'hk',
    path: '/hk',
    component: () => import('./view/hk'),
    meta: {
      title: '南洋头条',
    },
  },
  {
    name: 'content',
    path: '/content/:id',
    component: () => import('./view/content'),
    meta: {
      title: '',
    },
  },
  {
    name: 'person',
    path: '/person/:id',
    component: () => import('./view/person'),
    meta: {
      title: '',
    },
  },
  /*{
    name: 'cart',
    path: '/cart',
    component: () => import('./view/cart'),
    meta: {
      title: '购物车',
    },
  },
  {
    name: 'goods',
    path: '/goods',
    component: () => import('./view/goods'),
    meta: {
      title: '商品详情',
    },
  },*/
];

const router = createRouter({
  routes,
  history: createWebHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
